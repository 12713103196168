@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';

.faqMenuCard {
  margin-top: 26px;
  padding: 25px 12px 5px;

  @include mediaqueries.smallUp {
    margin-top: 40px;
  }
}

.faqContent {
  padding: 26px 0;
  h2 {
    color: colors.$neutral800;
    font-family: Jokker;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.48px;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    @include mediaqueries.smallUp {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -1.12px;
    }
  }

  p {
    margin: 0 -16px;
  }

  a {
    display: block;
    color: colors.$neutral600;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 16px;

    &:hover {
      color: colors.$purple500;
      font-weight: 500;
      background-color: colors.$purple100;
      border-radius: 8px;
    }

    @include mediaqueries.smallUp {
      font-size: 18px;
      padding: 15px 16px;
    }
  }

  @include mediaqueries.smallUp {
    padding: 40px 0;
  }
}

.faqCalloutCard {
  margin-bottom: 48px;
  @include mediaqueries.smallUp {
    margin-bottom: 72px;
  }
}

@use "$styles/variables/breakpoints";

@mixin extraSmallOnly {
  @media (max-width: (breakpoints.$smallBreakpoint - 1px)) {
    @content;
  }
}

@mixin extraSmallUp {
  @media (min-width: breakpoints.$extraSmallBreakpoint) {
    @content;
  }
}

@mixin smallOnly {
  @media (min-width: breakpoints.$smallBreakpoint) and (max-width: (breakpoints.$mediumBreakpoint - 1px)) {
    @content;
  }
}

@mixin upToSmall {
  @media (max-width: (breakpoints.$smallBreakpoint - 1px)) {
    @content;
  }
}

@mixin smallUp {
  @media (min-width: breakpoints.$smallBreakpoint) {
    @content;
  }
}


@mixin mediumOnly {
  @media (min-width: breakpoints.$mediumBreakpoint) and (max-width: (breakpoints.$largeBreakpoint - 1px)) {
    @content;
  }
}

@mixin upToMedium {
  @media (max-width: (breakpoints.$mediumBreakpoint - 1px)) {
    @content;
  }
}

@mixin mediumUp {
  @media (min-width: breakpoints.$mediumBreakpoint) {
    @content;
  }
}


@mixin largeOnly {
  @media (min-width: breakpoints.$largeBreakpoint) and (max-width: (breakpoints.$largeBreakpoint - 1px)) {
    @content;
  }
}

@mixin upToLarge {
  @media (max-width: (breakpoints.$largeBreakpoint - 1px)) {
    @content;
  }
}

@mixin largeUp {
  @media (min-width: breakpoints.$largeBreakpoint) {
    @content;
  }
}

@mixin extraLargeUp {
  @media (min-width: breakpoints.$extraLargeBreakpoint) {
    @content;
  }
}

@mixin upToExtraLarge {
  @media (max-width: (breakpoints.$extraLargeBreakpoint - 1px)) {
    @content;
  }
}
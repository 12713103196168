// PURPLES
$purple100: #F4F0FF;
$purple200: #EFEBFF;
$purple300: #DBD3FD;
$purple400: #C7BBFB;
$purple500: #5739D8;
$purple600: #4123B8;
$purple700: #250E75;
$purple800: #140054;

// WHITES/NEUTRALS
$white: #FFFFFF;

$neutral100: #F6F5FA;
$neutral200: #D6D5E3;
$neutral300: #9295A8;
$neutral400: #6D7287;
$neutral500: #474F66;
$neutral600: #2C3750;
$neutral700: #1B2640;
$neutral800: #020D27;

// YELLOWS
$yellow200: #FFD11C;
$yellow300: #F0BF00;
$yellow400: #E0B300;

// GREENS
$green100: #EFFEF3;
$green200: #BFECD0;
$green300: #2A9C46;
$green400: #186F35;
$green500: #0E5727;
$green600: #16632F;
$green700: #223C2B;

// CYANS
$cyan100: #DCF2FF;
$cyan200: #A0DFFF;
$cyan300: #4C9FC4;
$cyan400: #427994;
$cyan600: #293942;

// ALPHAS
$alphaWhite20: rgba(255, 255, 255, 0.2);
$alphaWhite50: rgba(255, 255, 255, 0.5);
$alphaWhite70: rgba(255, 255, 255, 0.7);
$alphaPurple30: rgba(195, 182, 251, 0.3);

// BRAND
$brandPurple: #6B4AFF;
$brandDarkPurple: #140054;
$brandYellow: #FFC205;
$brandCyan: #5EBEEA;
$brandBrightGreen: #1FD37E;
// @NOTE use US English because CSS is US English
$brandLightGray: #E5E3F0;

// REDS
$red100: #FCEBF1;
$red200: #F5BCCF;
$red300: #E01F60;
$red400: #BF194E;
$red500: #99183F;

// SECONDARY COLORS
$secondaryGreen400: #49725B;

// THIRD PARTY
$klarnaPurple: #0B051D;


// NEW tokens
$colorPurple100: $purple100;
$colorPurple200: $purple200;
$colorPurple300: $purple300;
$colorPurple400: $purple400;
$colorPurple500: $purple500;
$colorPurple600: $purple600;
$colorPurple700: $purple700;
$colorPurple800: $purple800;
$colorWhite: $white;
$colorNeutral100: $neutral100;
$colorNeutral200: $neutral200;
$colorNeutral300: $neutral300;
$colorNeutral400: $neutral400;
$colorNeutral500: $neutral500;
$colorNeutral600: $neutral600;
$colorNeutral700: $neutral700;
$colorNeutral800: $neutral800;
$colorYellow200: $yellow200;
$colorYellow300: $yellow300;
$colorYellow400: $yellow400;
$colorGreen100: $green100;
$colorGreen200: $green200;
$colorGreen300: $green300;
$colorGreen400: $green400;
$colorGreen500: $green500;
$colorGreen600: $green600;
$colorGreen700: $green700;
$colorCyan100: $cyan100;
$colorCyan200: $cyan200;
$colorCyan300: $cyan300;
$colorCyan400: $cyan400;
$colorCyan600: $cyan600;
$colorAlphaWhite20: $alphaWhite20;
$colorAlphaWhite50: $alphaWhite50;
$colorAlphaWhite70: $alphaWhite70;
$colorAlphaPurple30: $alphaPurple30;
$colorBrandPurple: $brandPurple;
$colorBrandDarkPurple: $brandDarkPurple;
$colorBrandYellow: $brandYellow;
$colorBrandCyan: $brandCyan;
$colorBrandBrightGreen: $brandBrightGreen;
$colorBrandLightGray: $brandLightGray;
$colorRed100: $red100;
$colorRed200: $red200;
$colorRed300: $red300;
$colorRed400: $red400;
$colorRed500: $red500;
$colorSecondaryGreen400: $secondaryGreen400;
$colorKlarnaPurple: $klarnaPurple;

:export {
  colorPurple100: $colorPurple100;
  colorPurple200: $colorPurple200;
  colorPurple300: $colorPurple300;
  colorPurple400: $colorPurple400;
  colorPurple500: $colorPurple500;
  colorPurple600: $colorPurple600;
  colorPurple700: $colorPurple700;
  colorPurple800: $colorPurple800;
  colorWhite: $colorWhite;
  colorNeutral100: $colorNeutral100;
  colorNeutral200: $colorNeutral200;
  colorNeutral300: $colorNeutral300;
  colorNeutral400: $colorNeutral400;
  colorNeutral500: $colorNeutral500;
  colorNeutral600: $colorNeutral600;
  colorNeutral700: $colorNeutral700;
  colorNeutral800: $colorNeutral800;
  colorYellow200: $colorYellow200;
  colorYellow300: $colorYellow300;
  colorYellow400: $colorYellow400;
  colorGreen100: $colorGreen100;
  colorGreen200: $colorGreen200;
  colorGreen300: $colorGreen300;
  colorGreen400: $colorGreen400;
  colorGreen500: $colorGreen500;
  colorGreen600: $colorGreen600;
  colorGreen700: $colorGreen700;
  colorCyan100: $colorCyan100;
  colorCyan200: $colorCyan200;
  colorCyan300: $colorCyan300;
  colorCyan400: $colorCyan400;
  colorCyan600: $colorCyan600;
  colorAlphaWhite20: $colorAlphaWhite20;
  colorAlphaWhite50: $colorAlphaWhite50;
  colorAlphaWhite70: $colorAlphaWhite70;
  colorAlphaPurple30: $colorAlphaPurple30;
  colorBrandPurple: $colorBrandPurple;
  colorBrandDarkPurple: $colorBrandDarkPurple;
  colorBrandYellow: $colorBrandYellow;
  colorBrandCyan: $colorBrandCyan;
  colorBrandBrightGreen: $colorBrandBrightGreen;
  colorBrandLightGray: $colorBrandLightGray;
  colorRed100: $colorRed100;
  colorRed200: $colorRed200;
  colorRed300: $colorRed300;
  colorRed400: $colorRed400;
  colorRed500: $colorRed500;
  colorSecondaryGreen400: $colorSecondaryGreen400;
  colorKlarnaPurple: $colorKlarnaPurple;
}
